<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
                <div class="card">
                    <div class="card-header text-white bg-success text-center h5"> সামাজিক লিংক </div>
                    <div class="card-body pt-1 text-center">
                        <div v-for="(info, index) in infos" :key="index">
                            <a :href="info.facebook" target="_blank"><i class="fa-brands fa-facebook-square p-1 face"></i></a>
                            <a :href="info.instgram" target="_blank"><i class="fab fa-instagram p-1 inst"></i></a>
                            <a :href="info.twiter" target="_blank"><i class="fab fa-twitter p-1 twtr"></i></a>
                            <a :href="info.youtube" target="_blank"><i class="fab fa-youtube p-1 you"></i></a>
                            <a :href="info.linkdin" target="_blank"><i class="fab fa-linkedin p-1 lnkd"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sdb } from '@/firebase';
import { collection, getDocs, query, limit } from "firebase/firestore";
      export default{
          data() {
              return {
                infos: [],
              }
          }, 
          methods: {
            async getData(){
              const q = query(collection(sdb, 'section_social'), limit(1))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
               this.infos.push({
                    key: doc.id,
                    facebook: doc.data().facebook,
                    youtube: doc.data().youtube,
                    instgram: doc.data().instgram,
                    twiter: doc.data().twiter,
                    linkdin: doc.data().linkdin,
                })
              });
            },
        },
        created() {
            this.getData();
        },
      }
  </script>

<style scoped>
.face{
    font-size: 30px;
    color: rgb(97, 86, 255);
}
.twtr{
    font-size: 30px;
    color:rgb(38, 0, 255);
}
.you{
    font-size: 30px;
    color:rgb(245, 0, 73)
}
.inst{
    font-size: 30px;
    color:rgb(250, 68, 123)
}
.lnkd{
    font-size: 30px;
    color:rgb(23, 100, 216)
}
</style>
  