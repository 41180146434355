<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-2">
          <div class="card-body p-1" v-for="info in infos" :key="info.key">
            <img alt="poster" :src="info.image" class="img-thumbnai img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import { sdb } from '@/firebase';
import { collection, getDocs, query, limit } from "firebase/firestore";
export default {
   data(){
       return{
          infos: [],
       }    
  },
  created() {
      this.getPoster();
  },
  methods: {
    async getPoster(){
      const q = query(collection(sdb, 'gallery_poster'), limit(1))
        const querySnapshot = await getDocs(q);
          this.infos = [];
          querySnapshot.forEach((doc) => {
          this.infos.push({
            key: doc.id,
            heading: doc.data().heading,
            image: doc.data().image,
        })
      });
    },
  }
}
</script>