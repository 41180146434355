import { initializeApp } from 'firebase/app'
import { getFirestore }  from 'firebase/firestore'
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAhni-5xENO41HyxM7diNkC6T0NiWweiS0",
    authDomain: "chambi103109-e628a.firebaseapp.com",
    projectId: "chambi103109-e628a",
    storageBucket: "chambi103109-e628a.appspot.com",
    messagingSenderId: "1066360611128",
    appId: "1:1066360611128:web:3ffb7d0a03daaa8c28e1cd"
  };

const app = initializeApp(firebaseConfig)
const sdb = getFirestore(app);
const rdb = getDatabase(app);
const idb = getStorage(app);
const auth = getAuth(app);
export { sdb, rdb, idb, auth };