<template>
    <div class="container">
      <div class="row pt-2 pb-2">
        <div class="col-md-12 p-0">
            <div class="card p-0">
                <div class="card-body p-1">
                    <div v-for="info in infos" :key="info.key" class="d-flex justify-content-between">
                    <div class="text-danger h5 align-middle m-2"> নোটিশ </div>
                      <Vue3Marquee :duration="50">
                        <div class="h5 align-middle m-2"> {{ info.title }} - {{info.notice}} </div>
                      </Vue3Marquee>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>

  
<script>
import { sdb } from '@/firebase';
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import { collection, getDocs, orderBy, query, limit } from "firebase/firestore";
export default {
  components: { Vue3Marquee },
  data(){
       return{
          infos: [],
       }    
  },
  created() {
      this.getNotice();
  },
  methods: {
      //Get Banner
      async getNotice(){
          const q = query(collection(sdb, 'gallery_notice'), orderBy('serial', "asc"), limit(1))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                key: doc.id,
                serial: doc.data().serial,
                title: doc.data().title,
                notice: doc.data().notice,   
              })
          });
      },

  }
}
</script>