<template>
    <div class="container p-0">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header text-white bg-danger text-center h5"> গুরুত্বপূর্ণ ব্যক্তিবর্গ </div>
                <div class="card-body">
                  <Carousel :items-to-show="1" :autoplay="3500" :wrap-around="true" :transition="500">
                    <Slide v-for="(info, index) in infos" :key="index">
                      <div @click.prevent="modalShowen(info.key)" style="cursor: pointer;"> <img :src="info.image" class="img-thumbnail img-fluid carousel__item"/></div>
                    </Slide>
                    <template #addons>
                      <Pagination />
                        <Navigation />
                    </template>
                  </Carousel>
                </div>
            </div>
        </div>
      </div>
    </div>
  <!-- Show Modal -->
  <div class="modal modal-lg fade" id="person" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content" id="element-to-convert">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> {{ info.person }} </h1>
          <button type="button" class="btn-close" @click="closeShowen()"></button>
        </div>
        <div class="modal-body p-2">
          <div class="d-flex justify-content-center">
            <img :src="info.image" class="img-thumbnail img-fluid"/>
          </div>
          <div class="h3 text-center"> {{ info.person }} </div>
          <div class="h4 text-center"> {{ info.designation }} </div>
          <div class="justify" v-html="info.biography"> </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeShowen()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>
  </template>

 
<script>
import { sdb } from '@/firebase';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { Modal } from 'bootstrap'
import { collection, getDocs, orderBy, getDoc, doc, query } from "firebase/firestore";
      export default{
        components: { Carousel, Slide, Pagination, Navigation },
          data() {
              return {
                showenModal: null,
                info: {
                    keyid: '',
                    serial: '',
                    person: '',
                    designation: '',
                    biography: '',
                    image: '',
                  },
                infos: [],
              }
          }, 
          methods: {
            async redData(){
              const q = query(collection(sdb, 'public_person'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  designation: doc.data().designation,
                  biography: doc.data().biography,
                  image: doc.data().image,
                })
              });
            },
            emptyForm(){
              this.info.serial = "";
              this.info.person = "";
              this.info.designation = "";
              this.info.biography = "";
              this.info.image = "";
              this.isImage = false;
            },
            closeShowen(){
              this.showenModal.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            async modalShowen(id){
              this.showenModal = new Modal(document.getElementById('person'));
              this.showenModal.show();
              const docRef = doc(sdb, "public_person", id);
              const docSnap = await getDoc(docRef);
              this.info.serial = docSnap.data().serial;
              this.info.person = docSnap.data().person;
              this.info.designation = docSnap.data().designation;
              this.info.biography = docSnap.data().biography;
              this.info.image = docSnap.data().image;
            },
        },
        created() {
            this.redData();
        },
      }
  </script>
  <style scoped‍>
  .justify{
    text-align: justify;
    font-size: 16px;
  }
  </style>

