<template>
  <div class="container">
      <div class="row">
          <div class="col-md-12">
            <div class="card">
            <div class="card-body">
                <div class="text-center fs-5"> ফটো গ্যালারী </div>
                <Carousel :items-to-show="2" :autoplay="1000" :wrap-around="true" :transition="500">
                  <Slide v-for="(info, index) in infos" :key="index">
                    <div @click.prevent="modalPhoto(info.key)" style="cursor: pointer;"><img :src="info.image" class="img-thumbnail img-fluid carousel__item"/></div>
                  </Slide>
                  <template #addons>
                      <Pagination />
                      <Navigation />
                  </template>
              </Carousel>
            </div>
          </div>
          </div>
      </div>
  </div>

  <!-- Show Modal -->
  <div class="modal modal-lg fade" id="getPhoto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content" id="element-to-convert">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> ফটো </h1>
          <button type="button" class="btn-close" @click="closeShowen()"></button>
        </div>
        <div class="modal-body p-2">
          <div class="d-flex justify-content-center">
            <img :src="info.image" class="img-thumbnail img-fluid"/>
          </div>
          <div class="justify" v-html="info.heading"> </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeShowen()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { sdb } from '@/firebase';
import { collection, getDocs, query, doc, getDoc } from "firebase/firestore";
import { Modal } from 'bootstrap'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
    components: { Carousel, Slide, Pagination, Navigation },
    data(){
        return{
          showenModal: null,
          info: {
            keyid: '',
            heading: '',
            image: '',
          },
          infos: [],
      }
    },
    
    methods: {
      async redData(){
            const q = query(collection(sdb, 'gallery_photo'))
            const querySnapshot = await getDocs(q);
            this.infos = [];
            querySnapshot.forEach((doc) => {
            this.infos.push({
              key: doc.id,
                heading: doc.data().heading,
                image: doc.data().image,
              })
            });
          },
          emptyForm(){
              this.info.serial = "";
              this.info.heading = "";
              this.info.image = "";
              this.isImage = false;
            },
            closeShowen(){
              this.showenModal.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            async modalPhoto(id){
              this.showenModal = new Modal(document.getElementById('getPhoto'));
              this.showenModal.show();
              const docRef = doc(sdb, "gallery_photo", id);
              const docSnap = await getDoc(docRef);
              this.info.serial = docSnap.data().serial;
              this.info.heading = docSnap.data().heading;
              this.info.image = docSnap.data().image;
            },
    },
    mounted(){
        this.redData();
    },
}
</script>

<style scoped>
.carousel__slide {
padding: 5px;
}

.carousel__viewport {
perspective: 2000px;
}

.carousel__track {
transform-style: preserve-3d;
}

.carousel__slide--sliding {
transition: 0.5s;
}

.carousel__slide {
opacity: 0.9;
transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
opacity: 1;
transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
opacity: 1;
transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
opacity: 1;
transform: rotateY(0) scale(1.1);
}
</style>

