<template>
    <div class="container">
      <div class="row mt-2">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header text-center fs-5"> কমিটি গ্যালারী </div>
                <div class="card-body p-0">
                    <Vue3Marquee :duration="25" :pause-on-hover="true">
                        <div class="card" v-for="info in infos" :key="info.key">
                            <img height="220" width="200" :src="info.image" class="img-thumbnail" />
                            <div class="text-center"> {{ info.person }} </div>
                            <div class="text-center"> {{ info.designation }} </div>
                            <div class="text-center"> {{ info.mobile }} </div>
                        </div>
                    </Vue3Marquee>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>

  
<script>
import { sdb } from '@/firebase';
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import { collection, getDocs, orderBy, query, } from "firebase/firestore";
export default {
  components: { Vue3Marquee },
  data(){
       return{
          infos: [],
       }    
  },
  created() {
      this.getNotice();
  },
  methods: {
      //Get Banner
      async getNotice(){
          const q = query(collection(sdb, 'human_committee'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                key: doc.id,
                serial: doc.data().serial,
                person: doc.data().person,
                designation: doc.data().designation,
                mobile: doc.data().mobile,
                image: doc.data().image,   
              })
          });
      },

  }
}
</script>