<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
                <div class="card">
                    <div class="card-header text-white bg-primary text-center h5"> গুরুত্বপূর্ণ লিংক </div>
                    <div class="card-body pt-1">
                        <div v-for="(info, index) in infos" :key="index">
                            <a :href="info.link" class="text-decoration-none" target="_blank" ><i class="fas fa-arrow-right"></i> {{ info.heading}} </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sdb } from '@/firebase';
import { collection, getDocs, orderBy, query } from "firebase/firestore";
      export default{
          data() {
              return {
                infos: [],
              }
          }, 
          methods: {
            async getData(){
              const q = query(collection(sdb, 'gallery_links'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
               this.infos.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  heading: doc.data().heading,
                  link: doc.data().link,
                })
              });
            },
        },
        created() {
            this.getData();
        },
      }
  </script>

<style scoped>
a:hover {
  color: rgb(255, 0, 13);
}
a{
    color: black;
}
</style>
  