<template>
    <div class="container p-0">
      <div class="row">
        <div class="col-md-12">
          <Carousel :items-to-show="1" :autoplay="2500" :wrap-around="true" :transition="500">
            <Slide v-for="(info, index) in infos" :key="index">
              <img :src="info.image" class="img-thumbnail img-fluid carousel__item"/>
            </Slide>
            <template #addons>
              <Pagination />
                <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </template>

 
<script>
import { sdb } from '@/firebase';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { collection, getDocs, query } from "firebase/firestore";
      export default{
        components: { Carousel, Slide, Pagination, Navigation },
          data() {
              return {
                  infos: [],
              }
          }, 
          methods: {
            //Fetch Data
            async redData(){
              const q = query(collection(sdb, 'gallery_slider'))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                  key: doc.id,
                  heading: doc.data().heading,
                  image: doc.data().image,
                })
              });
            },
        },
        created() {
            this.redData();
        },
      }
  </script>

