<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
                <div class="card">
                  <div class="card-header bg-info text-white text-center h5"> আবেদন করুন </div>
                    <div class="card-body p-2 text-center d-grid gap-2">
                        <button class="btn btn-lg btn-dark text-white" @click="modalEmployee()"> ইমপ্লয়ী </button>
                        <button class="btn btn-lg btn-dark text-white" @click="modalStudent()"> শিক্ষার্থী </button>
                        <button class="btn btn-lg btn-dark text-white" @click="modalOther()"> অন্যান্য </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Employee Modal -->
    <div class="modal modal-lg fade" id="employee" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addEmp">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel"> ইমপ্লয়ী আবেদন </h1>
              <button type="button" class="btn-close" @click="closeEmployee()"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> নাম </span>
                    <input type="text" class="form-control" v-model="info.person" required>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> মোবাইল </span>
                    <input type="text" class="form-control" v-model="info.mobile" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <Editor v-model="info.editorData" 
                    api-key="9aaqniej4aqp7ae6smh3inxc10l0yjg3sgc0ve1hl4n0id56"
                    :init="{
                      height: 500,
                      menubar: false,
                      branding: false,
                      plugins: [
                        'wordcount','table','lists',
                      ],
                      toolbar:
                      'bold italic underline align table mergetags numlist bullist'
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-danger" @click="closeEmployee()"> বন্ধ করুন </button>
              <button type="submit" class="btn btn-sm btn-success"> জমা করুন </button>
            </div>
          </form >
        </div>
      </div>
    </div>

    <!-- Student Modal -->
    <div class="modal modal-lg fade" id="student" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addStd">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">শিক্ষার্থী আবেদন </h1>
              <button type="button" class="btn-close" @click="closeStudent()"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> নাম </span>
                    <input type="text" class="form-control" v-model="info.person" required>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> মোবাইল </span>
                    <input type="text" class="form-control" v-model="info.mobile" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <Editor v-model="info.editorData" 
                    api-key="9aaqniej4aqp7ae6smh3inxc10l0yjg3sgc0ve1hl4n0id56"
                    :init="{
                      height: 500,
                      menubar: false,
                      branding: false,
                      plugins: [
                        'wordcount','table','lists',
                      ],
                      toolbar:
                      'bold italic underline align table mergetags numlist bullist'
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-danger" @click="closeStudent()"> বন্ধ করুন </button>
              <button type="submit" class="btn btn-sm btn-success"> জমা করুন </button>
            </div>
          </form >
        </div>
      </div>
    </div>

    <!-- Other Modal -->
    <div class="modal modal-lg fade" id="other" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addOtr">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel"> অন্যান্য আবেদন </h1>
              <button type="button" class="btn-close" @click="closeOther()"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> নাম </span>
                    <input type="text" class="form-control" v-model="info.person" required>
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"> মোবাইল </span>
                    <input type="text" class="form-control" v-model="info.mobile" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <Editor v-model="info.editorData" 
                    api-key="9aaqniej4aqp7ae6smh3inxc10l0yjg3sgc0ve1hl4n0id56"
                    :init="{
                      height: 500,
                      menubar: false,
                      branding: false,
                      plugins: [
                        'wordcount','table','lists',
                      ],
                      toolbar:
                      'bold italic underline align table mergetags numlist bullist'
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-danger" @click="closeOther()"> বন্ধ করুন </button>
              <button type="submit" class="btn btn-sm btn-success"> জমা করুন </button>
            </div>
          </form >
        </div>
      </div>
    </div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { Modal } from 'bootstrap'
import { sdb } from '@/firebase';
import { collection, addDoc, } from "firebase/firestore";
      export default{
        components: {
          Editor,
        },
          data() {
              return {
                employeeModal: null,
                studentModal: null,
                otherModal: null,
                info: {
                    keyid: '',
                    serial: '',
                    person: '',
                    mobile: '',
                    editorData: '',
                  },
                  infos: [],
              }
          }, 
          methods: {
            async addEmp(){
              await addDoc(collection(sdb, "public_employee"), {
                serial: Number(Math.round((new Date().getTime() / 1000))),
                person: this.info.person,
                mobile: this.info.mobile,
                editorData: this.info.editorData,
            }),
            this.emptyForm();
            this.employeeModal.hide();
            this.$toast.success("আবেদন পাঠানো হয়েছে",{position: "top-right",});
            },
            async addStd(){
              await addDoc(collection(sdb, "public_student"), {
                serial: Number(Math.round((new Date().getTime() / 1000))),
                person: this.info.person,
                mobile: this.info.mobile,
                editorData: this.info.editorData,
            }),
            this.emptyForm();
            this.studentModal.hide();
            this.$toast.success("আবেদন পাঠানো হয়েছে",{position: "top-right",});
            },
            async addOtr(){
              await addDoc(collection(sdb, "public_other"), {
                serial: Number(Math.round((new Date().getTime() / 1000))),
                person: this.info.person,
                mobile: this.info.mobile,
                editorData: this.info.editorData,
            }),
            this.emptyForm();
            this.otherModal.hide();
            this.$toast.success("আবেদন পাঠানো হয়েছে",{position: "top-right",});
            },
            //Clear Form
            emptyForm(){
              this.info.serial = "";
              this.info.person = "";
              this.info.mobile = "";
              this.info.editorData = "";
            },
            //Close Insert Modal
            closeEmployee(){
              this.employeeModal.hide();
              this.emptyForm();
              this.$toast.warning("ফরম বাতিল করা হয়েছে",{position:"top-right",});
            },
            closeStudent(){
              this.studentModal.hide();
              this.emptyForm();
              this.$toast.warning("ফরম বাতিল করা হয়েছে",{position:"top-right",});
            },
            closeOther(){
              this.otherModal.hide();
              this.emptyForm();
              this.$toast.warning("ফরম বাতিল করা হয়েছে",{position:"top-right",});
            },
            //Open Insert Modal
            modalEmployee(){
              this.employeeModal = new Modal(document.getElementById('employee'));
              this.employeeModal.show();
            },
            modalStudent(){
              this.studentModal = new Modal(document.getElementById('student'));
              this.studentModal.show();
            },
            modalOther(){
              this.otherModal = new Modal(document.getElementById('other'));
              this.otherModal.show();
            },
          },
      }
  </script>



  