<template>
    <div class="container">
        <div class="row bg-secondary text-white p-3 shadow-sm">
              <div class="col-md-3 text-start">
                  <div class="fs-3" > আমাদের ঠিকানা </div>
                  <div v-for="info in address" :key="info.key">
                    <div class="address" v-html="info.editorData"></div>
                  </div>
              </div>
            <div class="col-md-3 text-center">
                <div class="fs-3"> আমাদের অবস্থান </div>
                <div v-for="info in valid" :key="info.key">
                  <iframe :src="info.mapping" width="250" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <div class="fs-3"> কুইক লিংক </div>
                <div v-for="info in quick" :key="info.key">
                  <div class="lnk" style="cursor: pointer;"  @click="showQuick(info.serial)"><i class="fas fa-check"></i> {{ info.heading }} </div>
                </div>
            </div>
            <div class="col-md-3 text-center">
                <div class="fs-3"> যোগাযোগ করুন </div>
                <div>
                  <form @submit.prevent="addContact">
                      <div class="row">
                        <div class="col-md-12 mb-1">
                          <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="এখানে আপনার নাম লিখুন" v-model="info.person" required>
                          </div>
                        </div>
                        <div class="col-md-12 mb-1">
                          <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="এখানে মোবাইল নম্বর লিখুন" v-model="info.mobile" required>
                          </div>
                        </div>
                        <div class="col-md-12 mb-1">
                          <div class="input-group input-group-sm">
                            <textarea class="form-control" rows="4" placeholder="এখানে আপনার বক্তব্য লিখুন" v-model="info.contact" required></textarea>
                          </div>
                        </div>
                      </div>
                    <div>
                      <button type="reset" class="btn btn-sm btn-danger"> রিসেট </button> |
                      <button type="submit" class="btn btn-sm btn-primary"> সাবমিট </button>
                    </div>
                  </form >
                </div>
            </div>
        </div>
        <div class="row bg-dark">
          <div class="col-12 shadow-sm p-2">
              <div class="float-start ml-3 py-2 text-white" v-for="info in valid" :key="info.key">
                  <div> {{ info.year }} @ {{ info.school }} সকল অধিকার সংরক্ষন করে।</div>
              </div>
              <div class="float-end mr-3 py-2 text-white"> 
                 <a href="" target="_blank" class="text-decoration-none"> Powered by WTS</a>
              </div>
          </div>
      </div>
    </div>

    <!--Display Modal -->
    <div class="modal modal-lg fade" id="quickModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content" id="element-to-convert">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel" v-html="info.category"></h1>
            <button type="button" class="btn-close" @click="closeQuick()"></button>
          </div>
          <div class="modal-body p-2 table-responsive">
            <div class="table table-bordered mt-2">
              <div id="ttlPrint" class="txt" v-html="info.editorData"></div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-danger" @click="closeQuick()"> বন্ধ করুন </button>
          </div>
        </div>
      </div>
    </div>

  </template>


<script>
import { Modal } from 'bootstrap'
import { sdb } from '@/firebase';
import { collection, addDoc, getDocs, orderBy, query, limit, where } from "firebase/firestore";
      export default{
          data() {
              return {
                  showDisplay: null,
                  address: [],
                  quick: [],
                  valid: [],
                  info: {
                    category: '',
                    editorData: '',
                    serial: '',
                    person: '',
                    mobile: '',
                    contact: '',
                    date: '',
                  },
              }
          }, 
          methods: {
            //Clear Contact
            emptyContact(){
              this.info.serial = "";
              this.info.person = "";
              this.info.mobile = "";
              this.info.contact = "";
              this.info.date = "";
            },
            //Insert Data
            async addContact(){
              await addDoc(collection(sdb, "public_contact"), {
                serial: Number(Math.round((new Date().getTime() / 1000))),
                person: this.info.person,
                mobile: this.info.mobile,
                contact: this.info.contact,
                date: new Date().toJSON().slice(0,10).replace(/-/g,'.'),
            }),
            this.emptyContact();
            this.$toast.success("সফলভাবে তথ্য পাঠানো হয়েছে",{position: "top-right",});
            },
            //Fetch Address
            async getAddress(){
              const q = query(collection(sdb, 'section_address'), orderBy('serial', "asc"), limit(1))
              const querySnapshot = await getDocs(q);
              this.address = [];
              querySnapshot.forEach((doc) => {
              this.address.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  editorData: doc.data().editorData,
                })
              });
            },
            //Fetch Quick
            async getQuick(){
              const q = query(collection(sdb, 'section_quick'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.quick = [];
              querySnapshot.forEach((doc) => {
              this.quick.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  heading: doc.data().heading,
                })
              });
            },
            //Open Showen Modal
            async showQuick(id){
              this.showDisplay = new Modal(document.getElementById('quickModal'));
              this.showDisplay.show();
              const q = query(collection(sdb, "section_data"), where("serial", "==", id));
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                this.info.category = doc.data().category;
                this.info.editorData = doc.data().editorData;
              });
            },
            emptyForm(){
              this.info.category = "";
              this.info.editorData = "";
            },
            closeQuick(){
              this.showDisplay.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            //Fetch Valid
            async getValid(){
              const q = query(collection(sdb, 'section_social'), limit(1))
              const querySnapshot = await getDocs(q);
              this.valid = [];
              querySnapshot.forEach((doc) => {
              this.valid.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  school: doc.data().school,
                  mobile: doc.data().mobile,
                  mapping: doc.data().mapping,
                  facebook: doc.data().facebook,
                  youtube: doc.data().youtube,
                  instgram: doc.data().instgram,
                  twiter: doc.data().twiter,
                  linkdin: doc.data().linkdin,
                  year: doc.data().year,
                })
              });
            },
          },
         created() {
            this.getAddress();
            this.getQuick();
            this.getValid();
          },
      }

      
  </script>






<style scoped>
#ttlPrint{
  font-family: 'Kalpurush', Helvetica, Arial, sans-serif;
  color: black;
  font-size: 16px;
  background-color: white;
}
.txt :deep(table tr td){
      border: 1px solid black;
      border-color: rgb(74, 74, 75);
    }
    .txt :deep(table tr td){
      padding: 3px;
    }
    .txt :deep(table tr th){
      padding: 3px;
    }
    .txt :deep(table){
      width: 100%;
    }
    .txt :deep(p){
      padding: 0px;
      margin: 0px;
    }
    .txt :deep(h1, h2, h3, h4, h5, h6){
      padding: 0px;
      margin: 0px;
    }
.lnk:hover{
    color: red;
  }
.face{
  font-size: 30px;
  color: rgb(97, 86, 255);
}
.twtr{
  font-size: 30px;
  color:rgb(38, 0, 255);
}
.you{
  font-size: 30px;
  color:rgb(245, 0, 73)
}
.inst{
  font-size: 30px;
  color:rgb(250, 68, 123)
}
.lnkd{
  font-size: 30px;
  color:rgb(23, 100, 216)
}
.address :deep(p){
  margin: 0px;
  padding: 0px;
}
</style>