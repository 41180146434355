<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
                <div class="card">
                    <div class="card-body p-2 text-center d-grid gap-2">
                        <router-link class="btn btn-lg btn-dark text-white" :to="{name:'login'}"> <i class="fa-solid fa-right-to-bracket"></i> লগইন করুন </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  