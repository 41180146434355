<template>
    <div class="container">
      <div class="row">
        <div class="col-md-6" v-for="info in corners" :key="info.key">
          <div class="card mb-3">
            <div class="card-header text-white text-center h5 bg-primary"> {{ info.heading }} </div>
            <div class="row no-gutters">
              <div class="col-5">
                <img :src="info.image" class="img-fluid" style="height: 120px; width: 120px;" alt="ছবি" />
              </div>
              <div class="col-7">
                <div class="card-body">
                  <div style="cursor: pointer;"  @click="showCorner(info.slno1)"><i class="far fa-check-square"></i> {{ info.item1 }} </div>
                  <div style="cursor: pointer;"  @click="showCorner(info.slno2)"><i class="far fa-check-square"></i> {{ info.item2 }} </div>
                  <div style="cursor: pointer;"  @click="showCorner(info.slno3)"><i class="far fa-check-square"></i> {{ info.item3 }} </div>
                  <div style="cursor: pointer;"  @click="showCorner(info.slno4)"><i class="far fa-check-square"></i> {{ info.item4 }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card mb-3">
            <div class="card-header text-white text-center h5 bg-primary"> মানব সম্পদ </div>
            <div class="row no-gutters">
              <div class="col-5">
                <img src="https://firebasestorage.googleapis.com/v0/b/ucbd-a2d73.appspot.com/o/photo%2Fhuman.png?alt=media&token=adbc20d2-797c-4393-80ff-66949e0f89c3" class="img-fluid" style="height: 120px; width: 120px;" alt="ছবি" />
              </div>
              <div class="col-7">
                <div class="card-body">
                  <div style="cursor: pointer;" @click="humanOne()"><i class="far fa-check-square"></i> প্রতিষ্ঠাতা </div>
                  <div style="cursor: pointer;" @click="humanTwo()"><i class="far fa-check-square"></i> দাতাগণ </div>
                  <div style="cursor: pointer;" @click="humanThree()"><i class="far fa-check-square"></i> কমিটি </div>
                  <div style="cursor: pointer;" @click="humanFour()"><i class="far fa-check-square"></i> ইমপ্লয়ী </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card mb-3">
            <div class="card-header text-white text-center h5 bg-primary"> প্রাতিষ্ঠানিক </div>
            <div class="row no-gutters">
              <div class="col-5">
                <img src="https://firebasestorage.googleapis.com/v0/b/ucbd-a2d73.appspot.com/o/photo%2Fsearch.png?alt=media&token=3a91dc5c-67be-42ac-9f8e-f37f70d11383" class="img-fluid" style="height: 120px; width: 120px;" alt="ছবি" />
              </div>
              <div class="col-7">
                <div class="card-body">
                  <div style="cursor: pointer;" @click="searchOne()"><i class="far fa-check-square"></i> শিক্ষার্থী </div>
                  <div style="cursor: pointer;" @click="searchTwo()"><i class="far fa-check-square"></i> সনদ </div>
                  <div style="cursor: pointer;" @click="searchThree()"><i class="far fa-check-square"></i> মার্কশীট </div>
                  <div style="cursor: pointer;" @click="showNotice()"><i class="far fa-check-square"></i>  নোটিশ </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

    </div>


    <!--Display Modal -->
    <div class="modal modal-xl fade" id="cornerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content" id="element-to-convert">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel" v-html="info.category"></h1>
            <button type="button" class="btn-close" @click="closeCorner()"></button>
          </div>
          <div class="modal-body p-2 table-responsive">
            <div class="table table-bordered mt-2">
              <div class="txt" v-html="info.editorData"></div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-danger" @click="closeCorner()"> বন্ধ করুন </button>
          </div>
        </div>
      </div>
    </div>
    

  <!-- Estublish Modal -->
  <div class="modal modal-xl fade" id="humanOne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> প্রতিষ্ঠাতার তালিকা </h1>
          <button type="button" class="btn-close" @click="closeHum1()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <table style="border-collapse: collapse; width: 100%;" class="table table-bordered table-sm" >
              <thead>
                <tr >
                  <th> ক্রম </th>
                  <th> প্রতিষ্ঠাতার নাম </th>
                  <th class="text-center"> লিঙ্গ </th>
                  <th class="text-center"> পেশা </th>
                  <th class="text-center"> ঠিকানা </th>
                  <th class="text-center"> মোবাইল </th>
                  <th class="text-center"> ছবি </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(info, index) in establis" :key="info.key">
                  <td> {{ index+1 }} </td>
                  <td> {{ info.person }} </td>
                  <td class="text-center"> {{ info.gender }} </td>
                  <td class="text-center"> {{ info.profession }} </td>
                  <td class="text-center"> {{ info.address }} </td>
                  <td class="text-center"> {{ info.mobile }} </td>
                  <td class="text-center"> <img :src="info.image" style="height: 50px; width: 50px;"/></td>
                </tr>
              </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeHum1()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Donor Modal -->
  <div class="modal modal-xl fade" id="humanTwo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> দাতার তালিকা </h1>
          <button type="button" class="btn-close" @click="closeHum2()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <table style="border-collapse: collapse; width: 100%;" class="table table-bordered table-sm" >
              <thead>
                <tr >
                  <th> ক্রম </th>
                  <th> দাতার নাম </th>
                  <th class="text-center"> লিঙ্গ </th>
                  <th class="text-center"> পেশা </th>
                  <th class="text-center"> ঠিকানা </th>
                  <th class="text-center"> মোবাইল </th>
                  <th class="text-center"> ছবি </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(info, index) in donor" :key="info.key">
                  <td> {{ index+1 }} </td>
                  <td> {{ info.person }} </td>
                  <td class="text-center"> {{ info.gender }} </td>
                  <td class="text-center"> {{ info.profession }} </td>
                  <td class="text-center"> {{ info.address }} </td>
                  <td class="text-center"> {{ info.mobile }} </td>
                  <td class="text-center"> <img :src="info.image" style="height: 50px; width: 50px;"/></td>
                </tr>
              </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeHum2()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Employee Modal -->
  <div class="modal modal-xl fade" id="humanThree" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> কমিটির তালিকা </h1>
          <button type="button" class="btn-close" @click="closeHum3()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <table style="border-collapse: collapse; width: 100%;" class="table table-bordered table-sm" >
              <thead>
                <tr >
                  <th> ক্রম </th>
                  <th> নাম </th>
                  <th class="text-center"> পদবী </th>
                  <th class="text-center"> লিঙ্গ </th>
                  <th class="text-center"> ধর্ম </th>
                  <th class="text-center"> পেশা </th>
                  <th class="text-center"> মোবাইল </th>
                  <th class="text-center"> ছবি </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(info, index) in comite" :key="info.key">
                  <td> {{ index+1 }} </td>
                  <td> {{ info.person }} </td>
                  <td> {{ info.designation }} </td>
                  <td class="text-center"> {{ info.gender }} </td>
                  <td class="text-center"> {{ info.religion }} </td>
                  <td class="text-center"> {{ info.profession }} </td>
                  <td class="text-center"> {{ info.mobile }} </td>
                  <td class="text-center"> <img :src="info.image" style="height: 50px; width: 50px;"/></td>
                </tr>
              </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeHum3()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>


    <!-- Employee Modal -->
  <div class="modal modal-xl fade" id="humanFour" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> শিক্ষক/কর্মচারীর তালিকা </h1>
          <button type="button" class="btn-close" @click="closeHum4()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <table style="border-collapse: collapse; width: 100%;" class="table table-bordered table-sm" >
              <thead>
                <tr >
                  <th> ক্রম </th>
                  <th> শিক্ষক/কর্মচারীর নাম </th>
                  <th class="text-center"> পদবী </th>
                  <th class="text-center"> লিঙ্গ </th>
                  <th class="text-center"> ধর্ম </th>
                  <th class="text-center"> ক্যাটাগরী </th>
                  <th class="text-center"> মোবাইল </th>
                  <th class="text-center"> ছবি </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(info, index) in employe" :key="info.key">
                  <td> {{ index+1 }} </td>
                  <td> {{ info.person }} </td>
                  <td> {{ info.designation }} </td>
                  <td class="text-center"> {{ info.gender }} </td>
                  <td class="text-center"> {{ info.religion }} </td>
                  <td class="text-center"> {{ info.category }} </td>
                  <td class="text-center"> {{ info.mobile }} </td>
                  <td class="text-center"> <img :src="info.image" style="height: 50px; width: 50px;"/></td>
                </tr>
              </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeHum4()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>



  <!--Student Modal -->
  <div class="modal modal-lg fade" id="searchOne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> শিক্ষার্থী সার্চ করুন </h1>
          <button type="button" class="btn-close" @click="closeSrc1()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <form @submit.prevent="getStudent">
              <div class="row">
                <div class="col-md-8 mb-3">
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1"> আইডি নম্বর </span>
                      <input type="text" class="form-control" v-model="search.stdSearch" required>
                    </div>
                  </div>
                <div class="col-md-3 mb-3">
                  <div class="d-grid">
                    <button type="submit" class="btn btn-success"> সাবমিট </button>
                  </div>
                </div>
              </div>
            </form>
            <table class="table table-bordered mt-2" v-if="this.students.length > 0">
                <tbody>
                  <tr>
                    <td v-for="info in students" :key="info.key"> আইডি: {{ info.serial }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> শিক্ষার্থীর নাম: {{ info.student }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> পিতার নাম: {{ info.father }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> মাতার নাম: {{ info.mother }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> লিঙ্গ: {{ info.gender }}, জন্মতারিখ: {{ info.birth }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> গ্রাম: {{ info.village }}, ডাকঘর: {{ info.post }}, উপজেলা: {{ info.upazila }}, জেলা: {{ info.district }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> পরীক্ষা: {{ info.exam }}, রেজি: {{ info.registration }}, রোল: {{ info.roll }} </td>
                  </tr>
                  <tr>
                    <td v-for="info in students" :key="info.key"> বোর্ড: {{ info.board }}, জিপিএ: {{ info.gpa }}, গ্রেড: {{ info.grade }} </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="isData" class="text-center text-danger" > দুঃখিত, কোন তথ্য খুজে পাওয়া যায়নি। </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeSrc1()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Employee Modal -->
  <div class="modal modal-xl fade" id="noticeBoard" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> নোটিশ </h1>
          <button type="button" class="btn-close" @click="closeNotice()"></button>
        </div>
        <div class="modal-body p-2 table-responsive">
          <table style="border-collapse: collapse; width: 100%;" class="table table-bordered table-sm" >
            <thead>
                  <tr>
                    <th> ক্রমিক </th>
                    <th> শিরোনাম </th>
                    <th> ক্যাটাগরী </th>
                    <th> তারিখ </th>
                    <th class="text-center"> কার্যক্রম </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="info in notices" :key="info.key">
                    <td>{{ info.serial }}</td>
                    <td>{{ info.heading }}</td>
                    <td>{{ info.category }}</td>
                    <td>{{ info.date }}</td>
                    <td class="text-center">
                      <button type="button" @click.prevent="singleNotice(info.key)" class="btn btn-sm btn-primary">
                        <i class="fas fa-eye"></i>
                      </button> 
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeNotice()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>


    <!-- Print Notice -->
  <div class="modal fade" id="prnNtc" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content" id="element-to-convert">
          <div class="modal-body p-2 table-responsive">
            <div class="table table-bordered mt-2">
              <div id="printNotice" >
                <div v-for="info in banners" :key="info.key">
                  <img alt="Banner" :src="info.image" class="img-fluid"/>
                </div>
                <hr>
                <hr style="margin-top: -14px;">
                <div class="txt" v-html="info.editorData"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </template>





<script>
import { Modal } from 'bootstrap'
import { sdb } from '@/firebase';
import html2pdf from "html2pdf.js";
import { collection, getDocs, doc, getDoc, query, where, orderBy, limit } from "firebase/firestore";
      export default{
          data() {
              return {
                showDisplay: null,
                humOne: null,
                humTwo: null,
                humThree: null,
                humFour: null,
                srcOne: null,
                srcTwo: null,
                srcThree: null,
                srcFour: null,
                noticeBoard: null,
                corners: [],
                isData: false,
                info: {
                  category: '',
                  editorData: '',
                },
                search: {
                  stdSearch: null,
                },
                establis: [],
                donor: [],
                comite: [],
                employe: [],
                students: [],
                notices: [],
                banners: [],
              }
          }, 
          methods: {
            noticePrint() {
                html2pdf(document.getElementById('printNotice'), {
                margin: 0.5,
                filename: "print.pdf",
                image:        { type: 'jpeg', quality: 0.80 },
                html2canvas:  { scale: 2, dpi: 300, letterRendering: true, },
                jsPDF:        { unit: 'in', format: 'a4', orientation: 'p', }
              });
            },
            //Open Modal
            async showCorner(id){
              this.showDisplay = new Modal(document.getElementById('cornerModal'));
              this.showDisplay.show();
              const q = query(collection(sdb, "section_data"), where("serial", "==", id));
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                this.info.category = doc.data().category;
                this.info.editorData = doc.data().editorData;
              });
            },
            emptyForm(){
              this.info.category = "";
              this.info.editorData = "";
            },
            closeCorner(){
              this.showDisplay.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
             //Fetch Data
             async getCorner(){
              const q = query(collection(sdb, 'section_corner'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.corners = [];
              querySnapshot.forEach((doc) => {
              this.corners.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  heading: doc.data().heading,
                  item1: doc.data().item1,
                  slno1: doc.data().slno1,
                  item2: doc.data().item2,
                  slno2: doc.data().slno2,
                  item3: doc.data().item3,
                  slno3: doc.data().slno3,
                  item4: doc.data().item4,
                  slno4: doc.data().slno4,
                  image: doc.data().image,
                })
              });
            },
            //Fetch Estublish
            async humanOne(){
              this.humOne = new Modal(document.getElementById('humanOne'));
              this.humOne.show();
              const q = query(collection(sdb, 'human_estublish'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.establis = [];
              querySnapshot.forEach((doc) => {
              this.establis.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  gender: doc.data().gender,
                  profession: doc.data().profession,
                  address: doc.data().address,
                  mobile: doc.data().mobile,
                  image: doc.data().image,
                })
              });
            },
            closeHum1(){
              this.humOne.hide();
              this.establis = "";
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            //Fetch Donor
            async humanTwo(){
              this.humTwo = new Modal(document.getElementById('humanTwo'));
              this.humTwo.show();
              const q = query(collection(sdb, 'human_donor'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.donor = [];
              querySnapshot.forEach((doc) => {
              this.donor.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  gender: doc.data().gender,
                  profession: doc.data().profession,
                  address: doc.data().address,
                  mobile: doc.data().mobile,
                  image: doc.data().image,
                })
              });
            },
            closeHum2(){
              this.humTwo.hide();
              this.donor = "";
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            //Fetch Employee
            async humanThree(){
              this.humThree = new Modal(document.getElementById('humanThree'));
              this.humThree.show();
              const q = query(collection(sdb, 'human_committee'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.comite = [];
              querySnapshot.forEach((doc) => {
              this.comite.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  designation: doc.data().designation,
                  gender: doc.data().gender,
                  religion: doc.data().religion,
                  profession: doc.data().profession,
                  address: doc.data().address,
                  mobile: doc.data().mobile,
                  image: doc.data().image,
                })
              });
            },
            closeHum3(){
              this.humThree.hide();
              this.comite = "";
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            //Fetch Employee
            async humanFour(){
              this.humFour = new Modal(document.getElementById('humanFour'));
              this.humFour.show();
              const q = query(collection(sdb, 'human_employee'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.employe = [];
              querySnapshot.forEach((doc) => {
              this.employe.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  designation: doc.data().designation,
                  gender: doc.data().gender,
                  religion: doc.data().religion,
                  join: doc.data().join,
                  category: doc.data().category,
                  empid: doc.data().empid,
                  mobile: doc.data().mobile,
                  image: doc.data().image,
                })
              });
            },
            closeHum4(){
              this.humFour.hide();
              this.employe = "";
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            //Onen Student Modal
            async searchOne(){
              this.srcOne = new Modal(document.getElementById('searchOne'));
              this.srcOne.show();
            },
            //Search Student
            async getStudent(){
                const q = query(collection(sdb, 'document_testimonial'), where("serial", "==", this.search.stdSearch));
                const querySnapshot = await getDocs(q);
                this.students = [];
                querySnapshot.forEach((doc) => {
                this.students.push({
                    key: doc.id,
                    serial: doc.data().serial,
                    student: doc.data().student,
                    father: doc.data().father,
                    mother: doc.data().mother,
                    gender: doc.data().gender,
                    birth: doc.data().birth,
                    village: doc.data().village,
                    post: doc.data().post,
                    upazila: doc.data().upazila,
                    district: doc.data().district,
                    registration: doc.data().registration,
                    roll: doc.data().roll,
                    year: doc.data().year,
                    exam: doc.data().exam,
                    group: doc.data().group,
                    session: doc.data().session,
                    board: doc.data().board,
                    gpa: doc.data().gpa,
                    grade: doc.data().grade,
                  })
                });
                if(this.students.length === 0){
                  this.isData = true;
                }
              },
            closeSrc1(){
              this.srcOne.hide();
              this.search.stdSearch = "";
              this.students = "";
              this.isData = false;
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },

            //Fetch Notice
            async getNotice(){
              const q = query(collection(sdb, 'section_notice'), orderBy('serial', "desc"))
              const querySnapshot = await getDocs(q);
              this.notices = [];
              querySnapshot.forEach((doc) => {
              this.notices.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  heading: doc.data().heading,
                  category: doc.data().category,
                  date: doc.data().date,
                  editorData: doc.data().editorData,
                })
              });
            },
            closeNotice(){
              this.noticeBoard.hide();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            showNotice(){
              this.noticeBoard = new Modal(document.getElementById('noticeBoard'));
              this.noticeBoard.show();
            },
            //Open Showen Modal
              async singleNotice(id){
                const docRef = doc(sdb, "section_notice", id);
                const docSnap = await getDoc(docRef);
                this.info.serial = docSnap.data().serial;
                this.info.heading = docSnap.data().heading;
                this.info.category = docSnap.data().category;
                this.info.date = docSnap.data().date;
                this.info.editorData = docSnap.data().editorData;
                this.noticePrint();
              },
              async getBanner(){
                const q = query(collection(sdb, 'upload_banner'), where('serial','==', 1), limit(1))
                const querySnapshot = await getDocs(q);
                this.banners = [];
                querySnapshot.forEach((doc) => {
                this.banners.push({
                    key: doc.id,
                    serial: doc.data().serial,
                    image: doc.data().image,
                  })
                });
              },
          },
          created() {
            this.getCorner();
            this.getNotice();
            this.getBanner();
          },
      }
  </script>



<style scoped>
#ttlPrint{
  font-family: 'Kalpurush', Helvetica, Arial, sans-serif;
  color: black;
  font-size: 16px;
  background-color: white;
}
#printNotice{
    font-family: 'Kalpurush', Helvetica, Arial, sans-serif;
    color: black;
    background-color: white;
  }
.txt :deep(table tr td){
      border: 1px solid black;
      border-color: rgb(74, 74, 75);
    }
    .txt :deep(table tr td){
      padding: 3px;
    }
    .txt :deep(table tr th){
      padding: 3px;
    }
    .txt :deep(table){
      width: 100%;
    }
    .txt :deep(p){
      padding: 0px;
      margin: 0px;
    }
    .txt :deep(h1, h2, h3, h4, h5, h6){
      padding: 0px;
      margin: 0px;
    }
.card-body div:hover{
  color: red;
}
.card-body div a{
  color: black;
}
.card-body div a:hover{
  color: red;
}
</style>

