import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../auth/HomeView.vue'
import { auth } from '@/firebase';

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if(!user){
    next({ name: "login" })
  }else{
    next()
  }
}
const superAuth = (to, from, next) => {
  let user = auth.currentUser && localStorage.getItem('category') == 'super'
  if(!user){
    next({ name: "login" })
  }else{
    next()
  }
}
const adminAuth = (to, from, next) => {
  let user = auth.currentUser && localStorage.getItem('category') == 'admin'
  if(!user){
    next({ name: "login" })
  }else{
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login.html',
    name: 'login',
    component: () => import('../auth/LoginPage.vue')
  },
  {
    path: '/forgot.html',
    name: 'forgot',
    component: () => import('../auth/ForgotPage.vue')
  },
  {
    path: '/dashboard.html',
    name: 'dashboard',
    component: () => import('../auth/DashbordPage.vue'),
    beforeEnter: requireAuth,
  },
  //============================Super Section===========================>
  {
    path: '/super/user/1.html',
    name: 'sprUsr1',
    component: () => import('@/super/UserData.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/user/2.html',
    name: 'sprUsr2',
    component: () => import('@/super/UserAuth.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/user/3.html',
    name: 'sprUsr3',
    component: () => import('@/super/UserValid.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/user/4.html',
    name: 'sprUsr4',
    component: () => import('@/super/UserPyment.vue'),
    beforeEnter: superAuth,
  },
  //Payment
  {
    path: '/super/payment/1.html',
    name: 'sprPmt1',
    component: () => import('@/super/PaymentNumber.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/payment/2.html',
    name: 'sprPmt2',
    component: () => import('@/super/PaymentSystem.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/payment/3.html',
    name: 'sprPmt3',
    component: () => import('@/super/PaymentAmount.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/payment/4.html',
    name: 'sprPmt4',
    component: () => import('@/super/PaymentCategory.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/payment/5.html',
    name: 'sprPmt5',
    component: () => import('@/super/PaymentBanner.vue'),
    beforeEnter: superAuth,
  },
  {
    path: '/super/payment/6.html',
    name: 'sprPmt6',
    component: () => import('@/super/PaymentSign.vue'),
    beforeEnter: superAuth,
  },
  //============================Software Section===========================>
  //Computer Section
  {
    path: '/software/computer/form.html',
    name: 'sftCom1',
    component: () => import('@/software/ComputerForm.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/computer/admission.html',
    name: 'sftCom2',
    component: () => import('@/software/ComputerAdmission.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/computer/student.html',
    name: 'sftCom3',
    component: () => import('@/software/ComputerStudent.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/computer/expense.html',
    name: 'sftCom4',
    component: () => import('@/software/ComputerExpense.vue'),
    beforeEnter: adminAuth,
  },

  //Form
  {
    path: '/software/forms/admission.html',
    name: 'sftFrm1',
    component: () => import('@/software/FormAdmission.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/registration.html',
    name: 'sftFrm2',
    component: () => import('@/software/FormRegistration.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/formfillup.html',
    name: 'sftFrm3',
    component: () => import('@/software/FormFillup.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/computer.html',
    name: 'sftFrm4',
    component: () => import('@/software/FormComputer.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/science.html',
    name: 'sftFrm5',
    component: () => import('@/software/FormScience.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/library.html',
    name: 'sftFrm6',
    component: () => import('@/software/FormLibrary.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/forms/play.html',
    name: 'sftFrm7',
    component: () => import('@/software/FormPlay.vue'),
    beforeEnter: adminAuth,
  },

  {
    path: '/software/forms/culture.html',
    name: 'sftFrm8',
    component: () => import('@/software/FormCulture.vue'),
    beforeEnter: adminAuth,
  },
  //Document
  {
    path: '/software/document/certificate.html',
    name: 'sftDoc1',
    component: () => import('@/software/DocumentCertificate.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/document/testimonial.html',
    name: 'sftDoc2',
    component: () => import('@/software/DocumentTestimonial.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/document/testimonial.html',
    name: 'sftDoc3',
    component: () => import('@/software/DocumentTestimonial.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/software/document/other.html',
    name: 'sftDoc4',
    component: () => import('@/software/DocumentOther.vue'),
    beforeEnter: adminAuth,
  },


  //===========================Website Section=================================
  //Gallery
  {
    path: '/website/gallery/banner.html',
    name: 'webGal1',
    component: () => import('@/website/GalleryBanner.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/slider.html',
    name: 'webGal2',
    component: () => import('@/website/GallerySlider.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/photo.html',
    name: 'webGal3',
    component: () => import('@/website/GalleryPhoto.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/video.html',
    name: 'webGal4',
    component: () => import('@/website/GalleryVideo.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/poster.html',
    name: 'webGal5',
    component: () => import('@/website/GalleryPoster.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/hotline.html',
    name: 'webGal6',
    component: () => import('@/website/GalleryHotline.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/links.html',
    name: 'webGal7',
    component: () => import('@/website/GalleryLinks.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/gallery/notice.html',
    name: 'webGal8',
    component: () => import('@/website/GalleryNotice.vue'),
    beforeEnter: adminAuth,
  },
  //Human
  {
    path: '/website/human/estublish.html',
    name: 'webHum1',
    component: () => import('@/website/HumanEstublish.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/human/donor.html',
    name: 'webHum2',
    component: () => import('@/website/HumanDonor.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/human/committee.html',
    name: 'webHum3',
    component: () => import('@/website/HumanCommittee.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/human/employee.html',
    name: 'webHum4',
    component: () => import('@/website/HumanEmployee.vue'),
    beforeEnter: adminAuth,
  },

  //Sections
  {
    path: '/website/section/menu.html',
    name: 'webSct1',
    component: () => import('@/website/SectionMenu.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/corner.html',
    name: 'webSct2',
    component: () => import('@/website/SectionCorner.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/data.html',
    name: 'webSct3',
    component: () => import('@/website/SectionData.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/other.html',
    name: 'webSct4',
    component: () => import('@/website/SectionOther.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/address.html',
    name: 'webSct5',
    component: () => import('@/website/SectionAddress.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/social.html',
    name: 'webSct6',
    component: () => import('@/website/SectionSocial.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/quick.html',
    name: 'webSct7',
    component: () => import('@/website/SectionQuick.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/section/notice.html',
    name: 'webSct8',
    component: () => import('@/website/SectionNotice.vue'),
    beforeEnter: adminAuth,
  },
  //Public
  {
    path: '/website/public/person.html',
    name: 'webPub1',
    component: () => import('@/website/PublicPerson.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/message.html',
    name: 'webPub2',
    component: () => import('@/website/PublicMessage.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/acheive.html',
    name: 'webPub3',
    component: () => import('@/website/PublicAcheive.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/work.html',
    name: 'webPub4',
    component: () => import('@/website/PublicWork.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/contact.html',
    name: 'webPub5',
    component: () => import('@/website/PublicContact.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/employee.html',
    name: 'webPub6',
    component: () => import('@/website/PublicEmployee.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/student.html',
    name: 'webPub7',
    component: () => import('@/website/PublicStudent.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/website/public/other.html',
    name: 'webPub8',
    component: () => import('@/website/PublicOther.vue'),
    beforeEnter: adminAuth,
  },



  //===========================Setting Section=================================
  //Human
  {
    path: '/setting/human/class.html',
    name: 'setHum1',
    component: () => import('@/setting/HumanClass.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/setting/human/session.html',
    name: 'setHum2',
    component: () => import('@/setting/HumanSession.vue'),
    beforeEnter: adminAuth,
  },


  {
    path: '/setting/human/course.html',
    name: 'setHum5',
    component: () => import('@/setting/HumanCourse.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/setting/human/batch.html',
    name: 'setHum6',
    component: () => import('@/setting/HumanBatch.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/setting/human/student.html',
    name: 'setHum7',
    component: () => import('@/setting/HumanStudent.vue'),
    beforeEnter: adminAuth,
  },

  //Upload
  {
    path: '/setting/upload/banner.html',
    name: 'setUpl1',
    component: () => import('@/setting/UploadBanner.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/setting/upload/sign.html',
    name: 'setUpl2',
    component: () => import('@/setting/UploadSign.vue'),
    beforeEnter: adminAuth,
  },
  {
    path: '/setting/upload/logo.html',
    name: 'setUpl3',
    component: () => import('@/setting/UploadLogo.vue'),
    beforeEnter: adminAuth,
  },






  //Payment
  {
    path: '/admin/payment/1.html',
    name: 'admPmt1',
    component: () => import('@/user/AdminPayment.vue'),
    beforeEnter: adminAuth,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
