<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
              <div class="card p-3 bg-warning">
              <div class="card-body">
                <div @click.prevent="modalVideo()" style="cursor: pointer;">
                  <div class="text-center h3 text-white"> ভিডিও গ্যালারী </div>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
  
    <!-- Show Modal -->
    <div class="modal modal-lg fade" id="getVideo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content" id="element-to-convert">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel"> ভিডিও গ্যালারী </h1>
            <button type="button" class="btn-close" @click="closeShowen()"></button>
          </div>
          <div class="modal-body p-2">
            <div class="card w-100">
                <div class="card-body" v-for="info in infos" :key="info.key">
                  <div class="d-flex justify-content-center" >
                    <iframe width="560" height="315" :src="info.link" allowfullscreen></iframe><br/>
                  </div>
                  <div class="text-center"> {{ info.serial }} - {{ info.heading }} </div>
                </div>
              </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-danger" @click="closeShowen()"> বন্ধ করুন </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { sdb } from '@/firebase';
  import { collection, getDocs, query,} from "firebase/firestore";
  import { Modal } from 'bootstrap'
  export default {
      data(){
          return{
            showenModal: null,
            infos: [],
        }
      },
      methods: {
            closeShowen(){
              this.showenModal.hide();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            async modalVideo(){
              this.showenModal = new Modal(document.getElementById('getVideo'));
              this.showenModal.show();
              const q = query(collection(sdb, 'gallery_video'))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                key: doc.id,
                  heading: doc.data().heading,
                  link: doc.data().link,
                })
              });
          },
      },
  }
  </script>
<style scoped>

</style>
