<template>
    <div class="container">
        <div class="row mt-2">
            <div class="col-md-12 p-0">
              <div class="card mb-1" v-for="(info, index) in infos" :key="index">
              <div class="card-header bg-info text-white text-center h5"> {{info.heading}} </div>
              <div class="card-body">
                  <img :src="info.image" class="img-thumbnai img-fluid" alt="photo"/>
              </div>
            </div>
            </div>
        </div>
    </div>
</template>



<script>
import { sdb } from '@/firebase';
import { collection, getDocs, query } from "firebase/firestore";
      export default{
          data() {
              return {
                infos: [],
              }
          }, 
          methods: {
            async getData(){
              const q = query(collection(sdb, 'gallery_hotline'))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
               this.infos.push({
                  key: doc.id,
                  heading: doc.data().heading,
                  image: doc.data().image,
                })
              });
            },
        },
        created() {
            this.getData();
        },
      }
  </script>


  