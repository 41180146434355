<template>
  <div class="container pt-2 pb-5">
    <Header v-if="navigation"></Header>
    <router-view/>
    <Bottom v-if="navigation"></Bottom>
  </div>
</template>

<script>
import Header from '@/views/HeaderPage.vue';
import Bottom from '@/views/BottomPage.vue';



export default {
  components: {
      Header,
      Bottom,
  },
  data() {
    return{
      navigation: null,
    };
  },
  created(){
    this.checkRoute();
  },
  methods: {
      checkRoute(){
        if(this.$route.name === "home"){
          this.navigation = true;
          return;
        }this.navigation = false;
      }
    },
    watch:{
      $route(){
        this.checkRoute();
      }
    }
}
</script>

<style>
#app {
  font-family: Kalpurush, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 15px;
}
ul li a.router-link-exact-active {
  background-color: rgb(255, 0, 64);
  color: rgb(252, 252, 252);
}

@font-face {
    font-family: 'Kalpurush';
    src: local('Kalpurush'),
    url(.//assets/Kalpurush.ttf) format('truetype');
    font-style: normal;
}
table tr th, table tr td{
  white-space: nowrap;
}
</style>
