<template>
  <div class="container p-0">
    <div class="row">
      <div class="col-md-9">
        <SliderPage></SliderPage>
        <NoticePage></NoticePage>
      </div>
      <div class="col-md-3">
        <PersonPage></PersonPage>
        <LoginButton></LoginButton>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 p-0">
        <PosterPage></PosterPage>
        <CornerPage></CornerPage>
        <PhotoPage></PhotoPage>
        <ComitePage></ComitePage>
        <EmployePage></EmployePage>
        <StudentPage></StudentPage>
        <AcheivePage></AcheivePage>
      </div>
      <div class="col-md-3">
        <MesagePage></MesagePage>
        <EstublishPage></EstublishPage>
        <DonorPage></DonorPage>
        <HotlinePage></HotlinePage>
        <LinkPage></LinkPage>
        <SocialPage></SocialPage>
        <AplicationPage></AplicationPage>
        <VideoPage></VideoPage>
      </div>
    </div>
  </div>
</template>

<script>
import SliderPage from '@/views/SliderPage.vue'
import NoticePage from '@/views/NoticePage.vue'
import PersonPage from '@/views/PersonPage.vue'
import CornerPage from '@/views/CornerPage.vue'
import LoginButton from '@/views/LoginButton.vue'
import PosterPage from '@/views/PosterPage.vue'
import HotlinePage from '@/views/HotlinePage.vue'
import MesagePage from '@/views/MesagePage.vue'
import LinkPage from '@/views/LinkPage.vue'
import PhotoPage from '@/views/PhotoPage.vue'
import VideoPage from '@/views/VideoPage.vue'
import SocialPage from '@/views/SocialPage.vue'
import EstublishPage from '@/views/EstublishPage.vue'
import DonorPage from '@/views/DonorPage.vue'
import ComitePage from '@/views/ComitePage.vue'
import EmployePage from '@/views/EmployePage.vue'
import StudentPage from '@/views/StudentPage.vue'
import AplicationPage from '@/views/AplicationPage.vue'
import AcheivePage from '@/views/AcheivePage.vue'

export default {
  components: {
    SliderPage,
    NoticePage,
    PersonPage,
    CornerPage,
    PosterPage,
    LoginButton,
    HotlinePage,
    MesagePage,
    LinkPage,
    PhotoPage,
    VideoPage,
    SocialPage,
    EstublishPage,
    DonorPage,
    ComitePage,
    EmployePage,
    StudentPage,
    AplicationPage,
    AcheivePage,
  }
}
</script>
