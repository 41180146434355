import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { auth } from '@/firebase';

let app;
auth.onAuthStateChanged(() => {
    if(!app){
        app = createApp(App).use(router).use(store).use(Toaster).use(VueSweetalert2).mount('#app');
    }
})


