<template>
    <div class="container">
        <div class="row bck">
            <div class="col-12 p-2 shadow-sm">
                <div class="float-start ml-3 text-white date"><i class="fas fa-clock"></i> {{ timestamp }}</div>
                <div class="float-end mr-3 text-white" v-for="info in valid" :key="info.key"><i class="fas fa-mobile-alt"></i> {{ info.mobile }} </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 shadow-sm">
              <div v-for="info in banners" :key="info.key">
                <img alt="Banner" :src="info.image" class="img-thumbnai img-fluid"/>
              </div>
            </div>
        </div>
        <div class="row">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                <div class="container-fluid shadow-sm">
                    <router-link class="navbar-brand" to="/" v-for="info in valid" :key="info.key"> {{ info.school }} </router-link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item dropdown" v-for="info in menus" :key="info.key">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ info.heading }}
                                </a>
                                <ul class="dropdown-menu p-0">
                                    <li class="dropdown-item" style="cursor: pointer;" @click="showMenu(info.slno1)"> {{ info.item1 }} </li>
                                    <li class="dropdown-item" style="cursor: pointer;" @click="showMenu(info.slno2)"> {{ info.item2 }} </li>
                                    <li class="dropdown-item" style="cursor: pointer;" @click="showMenu(info.slno3)"> {{ info.item3 }} </li>
                                    <li class="dropdown-item" style="cursor: pointer;" @click="showMenu(info.slno4)"> {{ info.item4 }} </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <!--Display Modal -->
    <div class="modal modal-xl fade" id="menuModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content" id="element-to-convert">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel" v-html="info.category"></h1>
            <button type="button" class="btn-close" @click="closeMenu()"></button>
          </div>
          <div class="modal-body p-2 table-responsive">
            <div class="table table-bordered mt-2">
              <div id="ttlPrint" class="txt" v-html="info.editorData"></div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-danger" @click="closeMenu()"> বন্ধ করুন </button>
          </div>
        </div>
      </div>
    </div>

  </template>
  
  <script>
  import { Modal } from 'bootstrap'
  import { sdb } from '@/firebase';
  import { collection, getDocs, orderBy, query, limit, where } from "firebase/firestore";
  export default {
     data(){
         return{
            timestamp: "",
            menus: [],
            valid: [],
            banners: [],
            info: {
                category: '',
                editorData: '',
            },
         }    
    },
    created() {
        setInterval(this.getNow, 1000);
        this.getMenu();
        this.getValid();
        this.getBanner();
    },
    methods: {
        //Fetch Valid
        async getValid(){
              const q = query(collection(sdb, 'section_social'), limit(1))
              const querySnapshot = await getDocs(q);
              this.valid = [];
              querySnapshot.forEach((doc) => {
              this.valid.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  school: doc.data().school,
                  mobile: doc.data().mobile,
                  mapping: doc.data().mapping,
                  facebook: doc.data().facebook,
                  youtube: doc.data().youtube,
                  instgram: doc.data().instgram,
                  twiter: doc.data().twiter,
                  linkdin: doc.data().linkdin,
                  year: doc.data().year,
                })
              });
            },
          //Get Banner
          async getBanner(){
                const q = query(collection(sdb, 'gallery_banner'), limit(1))
                const querySnapshot = await getDocs(q);
                this.banners = [];
                querySnapshot.forEach((doc) => {
                this.banners.push({
                    key: doc.id,
                    image: doc.data().image,
                  })
                });
              },
        //Get Corner
        async getMenu(){
              const q = query(collection(sdb, 'section_menu'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.menus = [];
              querySnapshot.forEach((doc) => {
              this.menus.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  heading: doc.data().heading,
                  item1: doc.data().item1,
                  slno1: doc.data().slno1,
                  item2: doc.data().item2,
                  slno2: doc.data().slno2,
                  item3: doc.data().item3,
                  slno3: doc.data().slno3,
                  item4: doc.data().item4,
                  slno4: doc.data().slno4,
                })
              });
            },
          //Open Showen Modal
          async showMenu(id){
              this.showDisplay = new Modal(document.getElementById('menuModal'));
              this.showDisplay.show();
              const q = query(collection(sdb, "section_data"), where("serial", "==", id));
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                this.info.category = doc.data().category;
                this.info.editorData = doc.data().editorData;
              });
            },
            emptyForm(){
              this.info.category = "";
              this.info.editorData = "";
            },
            closeMenu(){
              this.showDisplay.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
        //Get Time
        getNow: function() {
            var asiaDhaka = new Date().toLocaleString([], { timeZone: "Asia/Dhaka" });
            var n = new Date(asiaDhaka);
            var y = n.getFullYear();
            var m = n.getMonth() + 1;
            var d = n.getDate();
            var h = n.getHours();
            var mi = n.getMinutes();
            var s = n.getSeconds();
            if (m == 1) m = "January";
            if (m == 2) m = "Feruary";
            if (m == 3) m = "March";
            if (m == 4) m = "April";
            if (m == 5) m = "May";
            if (m == 6) m = "June";
            if (m == 7) m = "July";
            if (m == 8) m = "August";
            if (m == 9) m = "September";
            if (m == 10) m = "October";
            if (m == 11) m = "November";
            if (m == 12) m = "December";
            var r = "PM";
            if (h < 12) r = "AM";
            if (h > 12) h = h - 12;
            if (h == 0) h = 12;
            if (mi <= 9) mi = "0" + mi;
            if (s <= 9) s = "0" + s;
            const date = h + " : " + mi + " : " + s + " " + r;
            const time = d + " " + m + " " + y;
            const dateTime = date +' '+ time;
            this.timestamp = dateTime;
        }
    }
  }
  </script>
  
  
  <style scoped>
  #ttlPrint{
  font-family: 'Kalpurush', Helvetica, Arial, sans-serif;
  color: black;
  font-size: 16px;
  background-color: white;
}
.txt :deep(table tr td){
      border: 1px solid black;
      border-color: rgb(74, 74, 75);
    }
    .txt :deep(table tr td){
      padding: 3px;
    }
    .txt :deep(table tr th){
      padding: 3px;
    }
    .txt :deep(table){
      width: 100%;
    }
    .txt :deep(p){
      padding: 0px;
      margin: 0px;
    }
    .txt :deep(h1, h2, h3, h4, h5, h6){
      padding: 0px;
      margin: 0px;
    }
  .bck{
    background-color:forestgreen;
  }
  .date {
  font-size: 15px;
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  </style>