<template>
    <div class="container p-0">
      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card mb-2" v-for="(info, index) in infos" :key="index">
            <div class="card-header text-white bg-success text-center h5"> {{ info.designation }} </div>
              <div class="card-body">
                <img :src="info.image" class="d-block w-100 img-thumbnai" alt="photo"/>
                <div class="text-center fw-bold">{{ info.person }}</div>
                <div class="text-center"> {{ info.designation }} </div>
                <div class="text-center"> {{ info.profesion }} </div>
                <div class="d-grid gap-2">
                  <button type="button" @click.prevent="modalShowen(info.key)" class="btn btn-sm btn-primary text-center"> বিস্তারিত </button> 
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Show Modal -->
  <div class="modal modal-lg fade" id="message" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content" id="element-to-convert">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"> {{ info.designation }} এর বানী </h1>
          <button type="button" class="btn-close" @click="closeShowen()"></button>
        </div>
        <div class="modal-body p-2">
          <div class="d-flex justify-content-center">
            <img :src="info.image" class="img-thumbnail img-fluid"/>
          </div>
          <div class="h3 text-center"> {{ info.person }} </div>
          <div class="h4 text-center"> {{ info.designation }} </div>
          <div class="h5 text-center"> {{ info.profesion }} </div>
          <div class="justify" v-html="info.mesage"> </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-danger" @click="closeShowen()"> বন্ধ করুন </button>
        </div>
      </div>
    </div>
  </div>
  </template>

 
<script>
import { sdb } from '@/firebase';
import { Modal } from 'bootstrap'
import { collection, getDocs, orderBy, getDoc, doc, query } from "firebase/firestore";
      export default{
          data() {
              return {
                showenModal: null,
                info: {
                    keyid: '',
                    serial: '',
                    person: '',
                    designation: '',
                    profesion: '',
                    mesage: '',
                    image: '',
                  },
                infos: [],
              }
          }, 
          methods: {
            async redData(){
              const q = query(collection(sdb, 'public_message'), orderBy('serial', "asc"))
              const querySnapshot = await getDocs(q);
              this.infos = [];
              querySnapshot.forEach((doc) => {
              this.infos.push({
                  key: doc.id,
                  serial: doc.data().serial,
                  person: doc.data().person,
                  designation: doc.data().designation,
                  profesion: doc.data().profesion,
                  mesage: doc.data().mesage,
                  image: doc.data().image,
                })
              });
            },
            emptyForm(){
              this.info.serial = "";
              this.info.person = "";
              this.info.designation = "";
              this.info.profesion = "";
              this.info.mesage = "";
              this.info.image = "";
            },
            closeShowen(){
              this.showenModal.hide();
              this.emptyForm();
              this.$toast.success("ডায়ালগ বন্ধ করা হয়েছে",{position:"top-right",});
            },
            async modalShowen(id){
              this.showenModal = new Modal(document.getElementById('message'));
              this.showenModal.show();
              const docRef = doc(sdb, "public_message", id);
              const docSnap = await getDoc(docRef);
              this.info.serial = docSnap.data().serial;
              this.info.person = docSnap.data().person;
              this.info.designation = docSnap.data().designation;
              this.info.profesion = docSnap.data().profesion;
              this.info.mesage = docSnap.data().mesage;
              this.info.image = docSnap.data().image;
            },
        },
        created() {
            this.redData();
        },
      }
  </script>
  <style scoped‍>
  .justify{
    text-align: justify;
    font-size: 18px;
  }
  </style>

